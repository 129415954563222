import { createStore } from 'vuex'
import { auth } from '@/store/auth.module'
import { profile } from '@/store/profile.module'
import { property } from '@/store/property.module'
import { devices } from '@/store/devices.module'
import { barriers } from '@/store/barriers.module'
import { integrations } from '@/store/integrations.module'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    profile,
    property,
    devices,
    barriers,
    integrations
  }
})
