import PropertyService from '@/services/property.service'
import router from '@/router'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const property = {
  namespaced: true,
  state: initialState,
  actions: {
    getUnit ({ commit }, unitId: string) {
      return PropertyService.getUnit(unitId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getUnits ({ commit }, url: string) {
      return PropertyService.getUnits(url).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getBuildingUnits ({ commit }, buildungId: string) {
      return PropertyService.getBuildingUnits(buildungId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    setPropertiesStepOne ({ commit }, data: any) {
      return PropertyService.setPropertiesStepOne(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    setPropertiesStepTwo ({ commit }, data: any) {
      return PropertyService.setPropertiesStepTwo(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    setPropertiesStepThree ({ commit }, data: any) {
      return PropertyService.setPropertiesStepThree(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    setPropertiesStepFour ({ commit }, data: any) {
      return PropertyService.setPropertiesStepFour(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    setProperties ({ commit }, unitId: string) {
      return PropertyService.setProperties(unitId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    publishUnit ({ commit }, data: string) {
      return PropertyService.publishUnit(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    unpublishedUnit ({ commit }, unitId: string) {
      return PropertyService.unpublishedUnit(unitId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    discardUnit ({ commit }, unitId: string) {
      return PropertyService.discardUnit(unitId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    // buildings
    //

    getBuildings ({ commit }, url: string) {
      return PropertyService.getBuildings(url).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    getBuilding ({ commit }, buildingId: string) {
      return PropertyService.getBuilding(buildingId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    createBuilding ({ commit }) {
      return PropertyService.createBuilding().then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    сreateUnit ({ commit }, buildingId: any) {
      return PropertyService.сreateUnit(buildingId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    draftUnit ({ commit }, unitId: any) {
      return PropertyService.draftUnit(unitId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    copyUnit ({ commit }, unitId: any) {
      return PropertyService.copyUnit(unitId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    discardBuilding ({ commit }, buildingId: any) {
      return PropertyService.discardBuilding(buildingId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    draftBuilding ({ commit }, buildingId: string) {
      return PropertyService.draftBuilding(buildingId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    saveBuildingSecondStep ({ commit }, buildingId: string) {
      return PropertyService.saveBuildingSecondStep(buildingId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    saveBuildingFirstStep ({ commit }, data: string) {
      return PropertyService.saveBuildingFirstStep(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    deleteBuilding ({ commit }, data: any) {
      return PropertyService.deleteBuilding(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    deleteUnit ({ commit }, unitId: string) {
      return PropertyService.deleteUnit(unitId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/owner')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    getLink ({ commit }, data: any) {
      return PropertyService.getLink(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    createSlots ({ commit }, data: any) {
      return PropertyService.createSlots(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    getSlots ({ commit }, unitId: string) {
      return PropertyService.getSlots(unitId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
