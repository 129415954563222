import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class IntegrationsService {
  getIntegrations (query: string) {
    return axios.get(API_URL + 'provider/integrations' + query, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getIntegrationsList () {
    return axios.get(API_URL + 'provider/integrations/list', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  unlinkIntegrations (integrationId: string) {
    return axios.post(API_URL + 'provider/integrations/' + integrationId + '/unlink', {}, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  authorizeNuki () {
    return axios.get(API_URL + 'integrations/nuki/authorize', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  authorizeSwitchBot (data: any) {
    return axios.post(API_URL + 'provider/integrations/switch-bot', {
      client_id: data.client_id,
      secret_id: data.secret_id,
      email: data.email
    }, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  checkConnection (data: any) {
    return axios.post(API_URL + 'provider/integrations/check-connection', {
      integration: 'cronofy'
    }, { headers: authHeader() })
      .then(response => {
        return response
      })
  }
}

export default new IntegrationsService()
